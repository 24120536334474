import React, { Component } from 'react'

import { currency, tierGold, tierBronze, tierSilver } from '../../assets'
import i18n from '../../i18n'

import './index.scss'

const icons = {
  gold: tierGold,
  bronze: tierBronze,
  silver: tierSilver
}

export default class MemberBenefits extends Component {
  componentWillMount = () => {
    // set locale
    i18n.setLocale(this.props)
  }

  render() {
    const tiers = ['bronze', 'silver', 'gold']

    return (
      <div className="member-benefits">
        <header>
          <h1>{i18n.t('memberBenefits_title')}</h1>
          <p>{i18n.t('memberBenefits_description')}</p>
        </header>
        <main>
          {tiers.map(tier => (
            <section key={tier} className={tier}>
              <figure>
                <img src={icons[tier]} alt={tier} />
              </figure>
              <div>
                <header>
                  <h2>{i18n.t(`memberBenefits_tier_${tier}_title`)}</h2>
                  <figure>
                    <figcaption>
                      {i18n.t(`memberBenefits_tier_${tier}_points`)}
                    </figcaption>
                    <img src={currency} alt="currency" />
                  </figure>
                </header>
                {i18n
                  .t(`memberBenefits_tier_${tier}_description`)
                  .split('\n')
                  .map(text => (
                    <p key={text}>{text}</p>
                  ))}
              </div>
            </section>
          ))}
        </main>
      </div>
    )
  }
}
