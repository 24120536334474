import i18n from 'i18n-js'

import de_AT from './locales/de_AT.json'
import de_CH from './locales/de_CH.json'
import de_DE from './locales/de_DE.json'
import en_AE from './locales/en_AE.json'
import en_AT from './locales/en_AT.json'
import en_CH from './locales/en_CH.json'
import en_BR from './locales/en_BR.json'
import en_CA from './locales/en_CA.json'
import en_DE from './locales/en_DE.json'
import en_FR from './locales/en_FR.json'
import en_GB from './locales/en_GB.json'
import en_JP from './locales/en_JP.json'
import en_US from './locales/en_US.json'
import es_ES from './locales/es_ES.json'
import es_US from './locales/es_US.json'
import fr_FR from './locales/fr_FR.json'
import fr_CH from './locales/fr_CH.json'
import ja_JP from './locales/ja_JP.json'
import pt_BR from './locales/pt_BR.json'

i18n.translations = {
  de_AT,
  de_CH,
  de_DE,
  en_AE,
  en_AT,
  en_CH,
  en_BR,
  en_CA,
  en_DE,
  en_FR,
  en_GB,
  en_JP,
  en_US,
  es_ES,
  es_US,
  fr_FR,
  fr_CH,
  ja_JP,
  pt_BR
}

i18n.locale = process.env.REACT_APP_DEFAULT_LOCALE || 'en_US'

i18n.setLocale = (props) => {
  const {
    match: {
      params: { locale = 'en-us' }
    }
  } = props

  const [country, language = ''] = locale.split('-')

  i18n.locale = `${country}_${language.toUpperCase()}`
}

export default i18n
