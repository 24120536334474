/**
 * get programcode by locale
 * @param {String} locale locale
 * @returns {String} programcode
 */
const getAllLocales = (locale) => {
  console.log('locale', locale)

  // Split the string by semicolon to get individual ProgramCode,locale~locale parts
  const parts = process.env.REACT_APP_MARKET_LOCALES.split(';')

  // Initialize an array to store all locales
  const allLocales = []

  // Iterate through each part and extract locales
  parts.forEach((part) => {
    // Split each part by comma to separate ProgramCode and locales
    const [, localeString] = part.split(',')

    // Split locales by tilde (~) to get individual locales
    const localeArray = localeString.split('~')

    // Concatenate the locales to the allLocales array
    allLocales.push(...localeArray)
  })

  console.log(allLocales)
  return allLocales
}

export default getAllLocales
